<template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.mykeywords-title")}}</h2>
            <p>
              {{$t("profile.infotexts.mykeywords")}}
            </p>
            <hr />
          </b-col>
        </b-row>
        <b-row class="account-my-keywords">
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
              
                <h3>{{$t("profile.contents.myprofile.mykeywords.keywords")}}</h3>
                <form>
                  <div class="input-group add-keyword-input">
                    <input spellcheck="true"
                      v-model="inputTag"
                      @keyup="delayedSearch"
                      type="text"
                      class="form-control"
                    />
                    <div class="input-group-append">
                      <button
                        @click.prevent="addTag"
                        class="btn btn-primary"
                        type="button"
                        id="button-addon2"
                      >
                        {{$t("buttons.add")}}
                      </button>
                      <br />
                    </div>
                    <div v-if="ac && isOpen" v-click-outside="() => (isOpen = false)" class="result-tag">
                      <ul class="list-group">
                        <li
                          @click="select(item.tag)"
                          v-for="(item, index) in acDiff"
                          :key="index"
                        >
                          <span>#{{ item.tag }}</span>
                          <span>{{ item.count }} used</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </form>
                <div class="added-tag">
                  <a
                    v-for="(item, index) in roTags"
                    :key="'ro-' + index"
                    class="mr-3 selected-industry-item"
                  >
                    #{{ item }}
                  </a>
                
                  <a
                    v-for="(item, index) in tags"
                    :key="'t-' + index"
                    class="mr-3 selected-industry-item"
                  >
                    #{{ item }}
                    <img
                      @click="deleteItem(index)"
                      src="/img/cross.svg"
                      alt=""
                    />
                  </a>
                </div>
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <hr/>
                     <h3>{{$t("profile.contents.myprofile.mykeywords.suggested")}}</h3>
                     <div class="added-tag">
                        <a
                            v-for="(item, index) in sDiff"
                            :key="'s-' + index"
                            class="mr-3 selected-industry-item"
                            href="javascript:void(0)"
                            @click.prevent="select(item)"
                        >
                            #{{ item }}
                        </a>
                    </div>
                </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="text-center">
            <p>
             {{$t("profile.contents.myprofile.mykeywords.comprate")}}
            </p>
            <b-row>
              <b-col cols="12" class="mb-4">
                <vc-donut
                  :sections="sections"
                  :start-angle="0"
                  :thickness="10"
                  class="chart-type"
                >
                  <h5>% {{ rate }}</h5>
                  <h6>{{$t("profile.contents.myprofile.mykeywords.cr-text")}}</h6>   
                </vc-donut>
              </b-col>
            </b-row>

            <p class="more-links">
                {{$t("profile.contents.myprofile.mykeywords.industry")}}:

                <span style="color:#198cff; letter-spacing:0.05em;">{{this.GET_USER_INFO.industry.name}}</span>
            
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
        <b-col offset="1" cols="11">
            <hr/>
            <button
                @click.prevent="submit()"
                class="btn btn-primary"
                type="button"
            >
                save
            </button>
        </b-col>
    </b-row>
  </b-container>
</template>


<script>
import AC_TAGS from "@/graphql/me/acTags.gql";
import ADD_TAGS from "@/graphql/me/addTags.gql";
import COMPATIBILITY_RATE from "@/graphql/me/profile/compatibilityRate.graphql";
import GET_TAGS from "@/graphql/me/profile/getTags.graphql";
import GET_PRODUCTS from '@/graphql/me/getProducts.gql';
import GET_INQUIRIES from '@/graphql/me/getInquiries.gql';
import GET_SUGGESTIONS from '@/graphql/me/profile/keywords.gql'
import _ from "lodash";
export default {
  data() {
    return {
      loading: false,
      tags: [],
      roTags: [],
      ac: [],
      industries: [],
      suggestions: [],
      isOpen: false,
      delay: null,
      inputTag: null,
      emptyTag: false,
      resultTagList: [],
      showDrop: false,
      sections: [
          { 
              value: 0, 
              color: "#198CFF" 
          }
      ],
      rate: 0,
      delayedSearch: null
    };
  },
  computed: {
      acDiff() {
          return _.differenceWith(this.ac, this.tags, (a,b) => a.tag == b);
      },
      sDiff() {
          return _.slice(_.difference(this.suggestions, this.tags), 0, 20);
      }
  },
  methods: {
      async getMyTags() {
          this.loading = true;
          let selector = await this.$apollo.query(GET_TAGS, {});
          let tags = _.map(_.get(_.find(selector('**.get.tags'), { default: true }), 'tags', []), x => x.tag);
          this.$set(this, 'tags', tags);
          this.loading = false;
      },

      async submit() {
          if (_.size(this.tags) === 0) {
              return this.$bvToast.toast(this.$t("Toaster.add-keywords"),
                   {
                       title: this.$t("Toaster.error-title"),
                       variant: "danger",
                       autoHideDelay: 1350,
                   }
               );
          }

          try {
              this.loading = true;
              await this.$apollo.mutate(ADD_TAGS, { tags: this.tags });
              return this.$bvToast.toast(this.$t("Toaster.kw-success"),
                   {
                       title: this.$t("Toaster.success-title"),
                       variant: "success",
                       autoHideDelay: 1350,
                   }
              );
          } catch (error) {
              return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
          } finally {
              this.loading = false;
          }
      },

      async search() {
          this.acc = [];
          this.isOpen = false;
          let params = {
              search: this.inputTag
          };
          let selector = await this.$apollo.query(AC_TAGS, params);
          let tags = selector('**.tags.autocomplete');

          if (_.size(tags) > 0) {
              this.isOpen = true;
              let stags = _.get(_.find(_.get(tags, '[0].tags', []), { default: true }), 'tags', []);
              this.$set(this, 'ac', stags);
          }
      },

      async addTag() {
          if (!this.inputTag) {
              return;
          }

          if (!this.isHaveTag(this.inputTag)) {
              this.tags.push(this.inputTag);
          }

          this.compatibilityRate();
          this.inputTag = null;
      },

      async compatibilityRate() {
          let selector = await this.$apollo.query(COMPATIBILITY_RATE, { tags: _.concat(this.tags, this.roTags) });
          let response = selector('**.compatibility');
          this.rate = response;
          this.sections[0].value = this.rate;
      },

      async buildTags() {
          let params = {
            limit: 3,
            filters: {},
          };

          let pselector = await this.$apollo.query(GET_PRODUCTS, params);
          let products = pselector('**.list.list');
          
          let iselector = await this.$apollo.query(GET_INQUIRIES, params);
          let inquiries = iselector('**.list.list');

        //   this.roTags = _.concat(this.roTags, _.map(products, x => _.find(x.contents, { default: true }).name));
          this.roTags = _.concat(this.roTags, _.map(products, x => x.industry.name));
          this.industries = _.concat(this.industries, _.map(products, x => x.industry.name));

        //   this.roTags = _.concat(this.roTags, _.map(inquiries, x => _.find(x.contents, { default: true }).name));
          this.roTags = _.concat(this.roTags, _.map(inquiries, x => x.industry.name));
          this.industries = _.concat(this.industries, _.map(inquiries, x => x.industry.name));


          this.roTags.push(this.GET_USER_INFO.industry.name);
          this.industries.push(this.GET_USER_INFO.industry.name);
        //   this.roTags.push(this.GET_USER_INFO.title.name);

          this.roTags = _.uniq(this.roTags);
      },

      async getSuggestions() {
          let selector = await this.$apollo.query(GET_SUGGESTIONS, { tags: this.industries });
          this.$set(this, 'suggestions',_.uniqWith(selector('**.suggestions'), (a,b) => _.lowerCase(a) === _.lowerCase(b)));
      },

      isHaveTag(tag) {
          return _.includes(this.tags, tag);  
      },
      
      select(tag) {
          this.tags.push(tag);
          this.compatibilityRate();
      },

      deleteItem(index) {
          this.tags.splice(index, 1);
          this.compatibilityRate();
      }
  },
  async created() {
      this.delayedSearch = _.debounce(() => this.search(), 1000);
      await this.getMyTags();
      await this.buildTags();
      await this.compatibilityRate();
      await this.getSuggestions();
  },
};
</script>
